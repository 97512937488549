import React, {useState} from "react";
import {PrimaryButton, type ICommandBarItemProps} from "@fluentui/react";
import ConstructionStatus from "../../../../types/ConstructionStatus";

const useConstructionCommandBarItems = (
  constructionStatus: ConstructionStatus | undefined,
  isAllowToTakeAction: boolean,
  goToCalculations: () => void,
  handleShowDeleteDialogOpen: () => void,
  handleNewCalculationTrigger: () => Promise<void>,
): ICommandBarItemProps[] => {
  const items: ICommandBarItemProps[] = [];
  const [disabled, setDisabled] = useState<boolean>(false);

  if (isAllowToTakeAction) {
    if (constructionStatus !== ConstructionStatus.Accepted) {
      items.push({
        key: "NewCalculation",
        onRender: () => (
          <PrimaryButton
            aria-label="New calculation"
            text="New calculation"
            iconProps={{
              iconName: "AddNotes",
            }}
            onClick={() => {
              setDisabled(prevState => !prevState);
              void handleNewCalculationTrigger();
              setTimeout((): void => {
                setDisabled(prevState => !prevState);
              }, 2000);
            }}
            disabled={disabled}
          />
        ),
      });

      items.splice(2, 0, {
        key: "Delete",
        onRender: () => (
          <PrimaryButton
            aria-label="Delete construction"
            text="Delete"
            iconProps={{
              iconName: "Delete",
            }}
            onClick={() => {
              handleShowDeleteDialogOpen();
            }}
            disabled={disabled}
          />
        ),
      });
    }
  }

  items.splice(1, 0, {
    key: "calculateResults",
    onRender: () => (
      <PrimaryButton
        aria-label="View calculations"
        text="View calculations"
        iconProps={{
          iconName: "Calculator",
        }}
        onClick={goToCalculations}
        disabled={disabled}
      />
    ),
  });

  return items;
};

export default useConstructionCommandBarItems;
