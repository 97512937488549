import React from "react";
import {useDispatch} from "react-redux";
import {Icon} from "@fluentui/react";

import styles from "./CalculationsPanelProfileCalculationItem.module.scss";
import type CalculationsPanelProfileCalculationItemProps from "./CalculationsPanelProfileCalculationItemProps";
import CalculationStatus from "../../../../types/CalculationStatus";
import SrvCore from "../../../../utils/_legacy/SrvCore";
import useCalculationsPanelProfileCalculationItem from "./useCalculationsPanelProfileCalculationItem";
import useCalculationHook from "../../../../hooks/useCalculationHook";
import {updateData} from "../../constructionCalculationsSlice";

const CalculationsPanelProfileCalculationItem: React.FunctionComponent<
  CalculationsPanelProfileCalculationItemProps
> = ({profileCalculation, calculation}) => {
  const {
    status,
    calculationId,
    createdBy,
    lastUpdatedOn,
    selected,
    mastResults,
  } = calculation;
  const {className, iconName} = useCalculationsPanelProfileCalculationItem(
    status as CalculationStatus,
  );

  const {getCalculationProfileResults} = useCalculationHook();
  const dispatch = useDispatch();

  const handleProfileCalculationClick = (): void => {
    void (async () => {
      const result = await getCalculationProfileResults(
        calculationId,
        profileCalculation?.profileCalculationId,
      );
      dispatch(
        updateData({
          id: calculationId,
          results: result.data,
        }),
      );
    })();
  };

  return (
    <div
      key={profileCalculation?.profileCalculationId}
      className={
        selected
          ? `${styles.calculationProfileItem} ${styles.selected}`
          : `${styles.calculationProfileItem}`
      }
      onClick={handleProfileCalculationClick}>
      <div className={styles.calculationProfileHeader}>
        <div className={styles.calculationProfileLeft}>
          <div className={styles.calculationProfileDetails}>
            <div
              className={styles.calculationProfileUser}
              title={`Started by ${createdBy.name}`}>
              <Icon iconName="Contact" />
              <span>{createdBy.name}</span>
              {calculation.isUsedToUpdateConstruction && (
                <Icon
                  iconName="CheckboxCompositeReversed"
                  className={styles.sourceCalculation}
                />
              )}
            </div>
            <div
              title={`Created at ${SrvCore.formatDate(new Date(lastUpdatedOn), "DD/MM/YYYY HH:MM")}`}
              className={styles.calculationProfileDate}>
              <Icon iconName="DateTime" />
              <span>
                {SrvCore.formatDate(
                  new Date(lastUpdatedOn),
                  "DD/MM/YYYY HH:MM",
                )}
              </span>
            </div>

            <div
              title="Used mast profiles"
              className={styles.calculationMastProfiles}>
              <Icon iconName="EntitlementRedemption" />
              {mastResults === undefined ||
                (mastResults === null && <span>-</span>)}
              {mastResults?.map((mast, index) => (
                <div key={index}>
                  <span>{`${mast.profileType}`}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.calculationProfileStatus} ${className}`}>
        <Icon iconName={iconName} />
        <span>
          {status === CalculationStatus.Pending ||
          status === CalculationStatus.Created
            ? "In progress"
            : status}
        </span>
      </div>
      {status === CalculationStatus.Pending ||
        (status === CalculationStatus.Created && (
          <div className={styles.progressIndicator}>
            <div className={styles.progressIndicatorItem}>
              <div className={styles.progressIndicatorTrack} />
              <div className={styles.progressIndicatorBar} />
            </div>
          </div>
        ))}
    </div>
  );
};
CalculationsPanelProfileCalculationItem.displayName =
  "CalculationsPanelProfileCalculationItem";

export default CalculationsPanelProfileCalculationItem;
