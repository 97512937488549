import React from "react";

import SrvCore from "../../../../utils/_legacy/SrvCore";
import ConstructionDetailsSection from "../detailsSection/ConstructionDetailsSection";
import type ConstructionBodyProps from "./ConstructionBodyProps";
import type ConstructionGUIData from "../../../../types/ConstructionGUIData";

const ConstructionBody: React.FunctionComponent<ConstructionBodyProps> = ({
  construction,
}) => {
  const data = SrvCore.getConstructionData(construction);

  return (
    <>
      {data && (
        <div>
          {data
            .filter((s: any) => s.parent === null)
            .map((section: ConstructionGUIData) => (
              <ConstructionDetailsSection
                key={section?.id}
                section={section}
                constructionId={construction?.id}
                constructionStatus={construction?.status}
                subSections={data.filter((s: any) => s.parent === section?.id)}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default ConstructionBody;
