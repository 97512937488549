interface SelectedEntity {
  entityType: string;
  entityId: string;
  selectedId?: string;
  checked?: boolean;
  parentId?: string;
  compartment?: number;
  position?: number | undefined;
}

const CHILD_CONNECTION = "childConnections";
export {type SelectedEntity, CHILD_CONNECTION};
