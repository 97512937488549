import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import type IConstruction from "../types/IConstruction";
import type {RootState} from "../../../store";
import {load, updateConnections} from "../constructionSlice";
import {load as loadConstructions} from "../../../pages/constructions/constructionsSlice";
import {getOrderByCreatedDate} from "../../../utils/DateFormatting";
import useConstructionHook from "../../../hooks/useConstructionHook";
import SrvCore from "../../../utils/_legacy/SrvCore";
import {useAuth} from "../../../services/auth/hooks/useAuth";
import {MessageType} from "../../../services/signalR/models/Messages";

const eventsThatShouldTriggerDataRefresh = [
  MessageType.ConstructionUpdated,
  MessageType.ConstructionAccepted,
];

const useConstructionPageHook = (): {
  construction: IConstruction | undefined;
  loading: boolean;
} => {
  const {id} = useParams();
  const {data: construction} = useSelector(
    (state: RootState) => state.construction,
  );
  const {messages} = useSelector((state: RootState) => state.signalRMessages);
  const {all} = useSelector((state: RootState) => state.constructions);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const {user} = useAuth();
  const {
    getConstructionById,
    getAllConstructions,
    getDefaultConstructionCalculationParameters,
  } = useConstructionHook();

  const loadDataAsync = async (): Promise<void> => {
    if (id !== undefined) {
      setLoading(true);
      const result = await getConstructionById(id);

      const defaultCalculationParameters =
        await getDefaultConstructionCalculationParameters();
      if (all.length <= 0) {
        const constructions = await getAllConstructions();
        dispatch(
          loadConstructions({
            data: getOrderByCreatedDate(constructions?.data ?? []),
          }),
        );
      }
      dispatch(
        load({
          currentConstruction: result?.data,
          defaultCalculationParameters: defaultCalculationParameters?.data,
          all,
        }),
      );
      dispatch(
        updateConnections(SrvCore.getConstructionConnections(result?.data)),
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    void (async () => {
      await loadDataAsync();
    })();
  }, [id, all]);

  useEffect(() => {
    const relatedMessages = messages.filter(
      m =>
        m.data?.constructionId !== id && !m.isRead && m.user === user.userName,
    );

    void (async () => {
      if (relatedMessages?.length > 0) {
        const firstUnreadMessage = relatedMessages[0];
        if (
          eventsThatShouldTriggerDataRefresh.some(
            e => e === firstUnreadMessage?.type,
          )
        ) {
          await loadDataAsync();
        }
      }
    })();
  }, [messages]);

  return {
    construction,
    loading,
  };
};

export default useConstructionPageHook;
