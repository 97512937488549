import "react-toastify/dist/ReactToastify.css";
import React, {Suspense} from "react";
import {initializeIcons} from "@uifabric/icons";
import {Stack, ThemeProvider, type IStackStyles} from "@fluentui/react";
import {Outlet, Navigate, useLocation} from "react-router-dom";
import {type WithTranslation, withTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import styles from "./AppLayout.module.scss";
import type {RootState} from "../../store";

import IsaacHeader from "../../components/isaacHeader/IsaacHeader";
import IsaacNavigation from "../../components/isaacNavigation/IsaacNavigation";
import IsaacNotifications from "../../components/isaacNotifications/IsaacNotifications";
import IsaacErrorModal from "../../components/isaacModalError/IsaacErrorModal";
import appLayoutTheme from "./AppLayoutTheme";
import {useAuth} from "../../services/auth/hooks/useAuth";

initializeIcons();

// Note: just for testing at this time
const bodyStackStyles: IStackStyles = {
  root: {
    flexFlow: "row",
    width: "100%",
  },
};

const AppLayout: React.FunctionComponent<WithTranslation> = () => {
  // Note: could still be improved by moving leftover logic to appropriate hooks
  const location = useLocation();

  const {isAuthenticated, user} = useAuth();

  const {errors} = useSelector((state: RootState) => state.errors);

  return isAuthenticated ? (
    <ThemeProvider theme={appLayoutTheme}>
      <Suspense>
        <IsaacNotifications userName={user?.userName} />
        <IsaacErrorModal errors={errors} />
        <Stack grow>
          <Stack.Item className={`${styles.headerStack}`} grow={1}>
            <IsaacHeader />
          </Stack.Item>
          <Stack.Item className={styles.bodyStack} grow={3}>
            <Stack styles={bodyStackStyles}>
              <IsaacNavigation />
              <Outlet />
            </Stack>
          </Stack.Item>
        </Stack>
      </Suspense>
    </ThemeProvider>
  ) : (
    <Navigate to="/login" replace state={{from: location}} />
  );
};

export default withTranslation()(AppLayout);
