import React from "react";
import type IsaacModalProps from "./IsaacModalProps";
import {Modal} from "@fluentui/react";

const IsaacModal: React.FunctionComponent<IsaacModalProps> = ({
  isOpen,
  children,
  className,
  containerClassName,
  overlay,
  handleDismiss,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      isAlert={true}
      isBlocking={true}
      className={className}
      overlay={overlay}
      containerClassName={containerClassName}
      onDismiss={handleDismiss}>
      {children}
    </Modal>
  );
};

export default IsaacModal;
