import React from "react";
import {PrimaryButton, type ICommandBarItemProps} from "@fluentui/react";

export const getDefaultCommandBarItems = (
  showSaveModifiedBtn: boolean | undefined,
  handleOpenClosePanel: () => void,
  handleSaveModifiedConstruction: () => Promise<void>,
  handleNavigateToCalculationsNote: () => void,
  handleDownloadExcelInputMatrixTrigger: () => Promise<void>,
  handleDownloadJsonInputMatrixTrigger: () => Promise<void>,
  handleDownloadConstructionJsonTrigger: () => Promise<void>,
  handleAcceptAndSaveConstruction?: () => Promise<void> | undefined,
): {
  commandBarItems: ICommandBarItemProps[];
  commandBarFarItems: ICommandBarItemProps[];
} => {
  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "calculations",
      onRender: () => (
        <PrimaryButton
          aria-label="View all calculations"
          text="View all calculations"
          iconProps={{iconName: "SidePanel"}}
          onClick={handleOpenClosePanel}
        />
      ),
    },
    {
      key: "calculationNote",
      onRender: () => (
        <PrimaryButton
          aria-label="Calculation note"
          text="Calculation note"
          iconProps={{iconName: "WordDocument"}}
          onClick={handleNavigateToCalculationsNote}
        />
      ),
    },
  ];

  if (showSaveModifiedBtn !== undefined && showSaveModifiedBtn) {
    commandBarItems.push({
      key: "saveModified",
      onRender: () => (
        <PrimaryButton
          aria-label="Save modified construction"
          text="Save modified construction"
          iconProps={{iconName: "SaveAs"}}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSaveModifiedConstruction}
        />
      ),
    });
  }

  if (handleAcceptAndSaveConstruction) {
    commandBarItems.push({
      key: "acceptAndModify",
      onRender: () => (
        <PrimaryButton
          aria-label="Accept and save construction"
          text="Accept and save construction"
          iconProps={{iconName: "Accept"}}
          onClick={() => {
            void handleAcceptAndSaveConstruction();
          }}
        />
      ),
    });
  }

  const commandBarFarItems: ICommandBarItemProps[] = [
    {
      key: "InputMatrix",
      onRender: () => (
        <PrimaryButton
          aria-label="Input Data"
          text="Input Data"
          iconProps={{
            iconName: "Download",
          }}
          menuProps={{
            items: [
              {
                key: "downloadExcelMatrix",
                text: "Input Matrix.xlsx",
                iconProps: {iconName: "ExcelDocument"},
                onClick: () => {
                  void handleDownloadExcelInputMatrixTrigger();
                },
              },
              {
                key: "downloadJsonMatrix",
                text: "Input Matrix.json",
                iconProps: {iconName: "Embed"},
                onClick: () => {
                  void handleDownloadJsonInputMatrixTrigger();
                },
              },
              {
                key: "downloadJsonConstruction",
                text: "Construction.json",
                iconProps: {iconName: "FileCode"},
                onClick: () => {
                  void handleDownloadConstructionJsonTrigger();
                },
              },
            ],
          }}
        />
      ),
    },
  ];

  return {commandBarItems, commandBarFarItems};
};
