import React, {type ReactNode, createContext, useState, useEffect} from "react";
import {type AccountInfo, PublicClientApplication} from "@azure/msal-browser";
import {Spinner, SpinnerSize} from "@fluentui/react";
import {useDispatch, useSelector} from "react-redux";

import type IAuthContext from "./types/IAuthContext";
import type {RootState} from "../../store";
import type UserInfo from "../../types/AadUserInfo";
import UserRoles from "../../types/UserRoles";
import msalConfig from "./authConfig";
import Api from "./types/IsaacApi";
import {updateAuthTokens, updateUserInfo} from "./userInfoSlice";

const msalInstance = new PublicClientApplication(msalConfig);

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const AuthProvider: React.FunctionComponent<{children: ReactNode}> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [account, setAccount] = useState<AccountInfo | null>(null);
  const [isMsalInit, setIsMsalInit] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const {tokens} = useSelector((state: RootState) => state.userInfo);
  const dispatch = useDispatch();

  /** Interval to check if the token is expired.  */
  const refreshIntervalIsSeconds: number = 1000;
  const scopes = process.env.REACT_APP_AAD_SCOPES?.split(",") ?? [""];

  // Note: refactor this logic
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      setIsMsalInit(true);
      await msalInstance.initialize();
      setIsMsalInit(false);

      await msalInstance.handleRedirectPromise();

      const account = msalInstance.getAllAccounts()[0];
      if (account !== undefined) {
        msalInstance.setActiveAccount(account);
      }

      await setAccessTokens(account);

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const refreshInterval = setInterval(async () => {
      if (
        account &&
        tokens?.some(token => isTokenExpired(token.expiresOnTimestamp))
      ) {
        console.log("Token has expired, attempting refresh!");
        await setAccessTokens(account);
      }
    }, refreshIntervalIsSeconds);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [tokens, account]);

  const isTokenExpired = (expiresOnTimestamp: number | undefined): boolean =>
    expiresOnTimestamp ? Date.now() > expiresOnTimestamp : false;

  const setAccessTokens = async (account: AccountInfo): Promise<void> => {
    if (account !== undefined) {
      try {
        const iccPromise = msalInstance.acquireTokenSilent({
          account,
          scopes,
        });

        const isaacPromise = msalInstance.acquireTokenSilent({
          account,
          scopes: process.env.REACT_APP_AAD_EXTRA_SCOPES?.split(",") ?? [],
        });

        const storageTokenPromise = msalInstance.acquireTokenSilent({
          account,
          scopes: process.env.REACT_APP_AAD_STORAGE_SCOPES?.split(",") ?? [],
        });

        const [iccResult, isaacResult, storageTokenResult] = await Promise.all([
          iccPromise,
          isaacPromise,
          storageTokenPromise,
        ]);

        setAccount(account);
        setIsAuthenticated(true);

        dispatch(
          updateUserInfo({
            email: account?.username,
            roles: account?.idTokenClaims?.roles,
            userName: account.name,
          }),
        );

        dispatch(
          updateAuthTokens([
            {
              api: Api.ICC,
              accessToken: iccResult?.accessToken,
              expiresOnTimestamp: iccResult.expiresOn?.getTime(),
            },
            {
              api: Api.ISAAC,
              accessToken: isaacResult?.accessToken,
              expiresOnTimestamp: isaacResult.expiresOn?.getTime(),
            },
            {
              api: Api.STORAGE,
              accessToken: storageTokenResult.accessToken,
              expiresOnTimestamp: storageTokenResult.expiresOn?.getTime(),
            },
          ]),
        );
      } catch (error) {
        setIsAuthenticated(false);
        await msalInstance.clearCache();
      }
    }
  };

  const signIn = async (): Promise<void> => {
    try {
      if (!isMsalInit) {
        await msalInstance.initialize();
      }
      const loginResponse = await msalInstance.loginPopup({
        scopes,
        extraScopesToConsent:
          process.env.REACT_APP_AAD_EXTRA_SCOPES?.split(",") ?? [],
      });

      msalInstance.setActiveAccount(loginResponse.account);

      await setAccessTokens(loginResponse.account);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const signOut = async (): Promise<void> => {
    await msalInstance.logoutRedirect({
      account,
    });

    setAccount(null);
    setIsAuthenticated(false);
    dispatch(updateAuthTokens(undefined));
  };

  const user: UserInfo = {
    email: account?.username,
    userName: account?.name,
    roles: account?.idTokenClaims?.roles,
    isAllowedToTakeAction: () =>
      account?.idTokenClaims?.roles?.some(r =>
        [UserRoles.CADDesigner, UserRoles.DesignEngineer].includes(
          r as UserRoles,
        ),
      ) ?? false,
    isInRole: (allowedRoles: UserRoles[] = []) =>
      account?.idTokenClaims?.roles?.some(r =>
        allowedRoles.includes(r as UserRoles),
      ) ?? false,
  };

  // Note: Temp fix for redirection to home page
  if (loading) {
    return (
      <Spinner
        style={{width: "100%", height: "100%"}}
        size={SpinnerSize.large}
      />
    );
  }

  return (
    <AuthContext.Provider
      value={{isAuthenticated, user, signIn, signOut, tokens}}>
      {children}
    </AuthContext.Provider>
  );
};
