import React from "react";
import {
  DefaultButton,
  PrimaryButton,
  type ICommandBarItemProps,
} from "@fluentui/react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../services/auth/hooks/useAuth";
import PathConstants from "../../../routes/pathConstants";
import ConstructionStatus from "../../../types/ConstructionStatus";
import IsaacTagDialogType from "../components/tags/types/IsaacTagDialogType";

const useConstructionCommandBarItems = (
  handleTagDialogDismiss: (type: string) => void,
  handleDeleteConstructionTrigger: (items: any[]) => Promise<void>,
): ((selectedConstructions: any[]) => ICommandBarItemProps[]) => {
  const navigate = useNavigate();
  const {user} = useAuth();
  const isAllowToTakeAction = user?.isAllowedToTakeAction();

  const AddDeleteButton = (
    selectedConstructions: any[],
    commandBarItems: ICommandBarItemProps[],
  ): void => {
    if (selectedConstructions.length > 0) {
      const isAllowedToDelete =
        isAllowToTakeAction &&
        selectedConstructions?.every(
          c => c.status !== ConstructionStatus.Accepted,
        );
      if (isAllowedToDelete) {
        commandBarItems.push({
          key: "Delete",
          type: "button",
          onRender: () => (
            <PrimaryButton
              aria-label="Delete construction"
              text="Delete"
              iconProps={{
                iconName: "Delete",
              }}
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                handleDeleteConstructionTrigger(selectedConstructions);
              }}
            />
          ),
          disabled: false,
        });
      }
    }
  };

  const AddViewCalculationResultsButton = (
    selectedConstructions: any[],
    commandBarItems: ICommandBarItemProps[],
  ): void => {
    if (selectedConstructions.length === 1) {
      commandBarItems.push({
        key: "calculateResults",
        type: "button",
        onRender: () => (
          <PrimaryButton
            aria-label="View Calculation Result"
            text="View Calculation Result"
            iconProps={{
              iconName: "Calculator",
            }}
            onClick={() => {
              navigate(
                `${PathConstants.Constructions}/${selectedConstructions[0].id}/${PathConstants.Calculations}`,
              );
            }}
          />
        ),
        disabled: false,
      });
    }
  };

  const AddSelectConstructionButton = (
    selectedConstructions: any[],
    commandBarItems: ICommandBarItemProps[],
  ): void => {
    if (selectedConstructions.length <= 0) {
      commandBarItems.push({
        key: "Actions",
        onRender: () => (
          <DefaultButton
            aria-label="Constructions actions"
            text="Select one or more constructions for action bar to show"
            iconProps={{
              iconName: "SetAction",
            }}
            disabled={true}
          />
        ),
      });
    }
  };

  const AddManageTagsButton = (
    selectedConstructions: any[],
    commandBarItems: ICommandBarItemProps[],
  ): void => {
    if (isAllowToTakeAction) {
      if (selectedConstructions.length === 1) {
        commandBarItems.push({
          key: "ManageTags",
          onRender: () => (
            <PrimaryButton
              aria-label="Constructions actions"
              text="Manage Tags"
              iconProps={{
                iconName: "Tag",
              }}
              menuProps={{
                items: [
                  {
                    key: "emailMessage",
                    text: "Add tags",
                    iconProps: {iconName: "Add"},
                    onClick: () => {
                      handleTagDialogDismiss(IsaacTagDialogType.ADD);
                    },
                  },
                  {
                    key: "calendarEvent",
                    text: "Remove tags",
                    iconProps: {iconName: "Remove"},
                    onClick: () => {
                      handleTagDialogDismiss(IsaacTagDialogType.DELETE);
                    },
                  },
                ],
              }}
            />
          ),
        });
      }
    }
  };

  const AddViewDetailsButton = (
    selectedConstructions: any[],
    commandBarItems: ICommandBarItemProps[],
  ): void => {
    if (selectedConstructions.length === 1) {
      commandBarItems.push({
        key: "Details",
        onRender: () => (
          <PrimaryButton
            aria-label="view details of construction"
            text="Details"
            iconProps={{
              iconName: "View",
            }}
            onClick={() => {
              navigate(
                `${PathConstants.Constructions}/${selectedConstructions[0].id}`,
              );
            }}
          />
        ),
        disabled: false,
      });
    }
  };

  const getItems = (selectedConstructions: any[]): ICommandBarItemProps[] => {
    const commandBarItems: ICommandBarItemProps[] = [];

    AddSelectConstructionButton(selectedConstructions, commandBarItems);
    AddViewDetailsButton(selectedConstructions, commandBarItems);
    AddDeleteButton(selectedConstructions, commandBarItems);
    AddViewCalculationResultsButton(selectedConstructions, commandBarItems);
    AddManageTagsButton(selectedConstructions, commandBarItems);

    return commandBarItems;
  };

  return getItems;
};

export default useConstructionCommandBarItems;
