import React from "react";
import {Stack} from "@fluentui/react";

// TODO: Migrate styles to bodyStyles
import styles from "../sidePanel/ConstructionCalculationsPanel.module.scss";
import bodyStyles from "./ConstructionCalculationsBody.module.scss";
import type ConstructionCalculationsBodyProps from "./ConstructionCalculationsBodyProps";
import CalculationMastTabs from "../tabs/CalculationMastTabs";
import CalculationMastResults from "../mastsResults/CalculationMastResults";
import IsaacEmpty from "../../../../components/isaacEmpty/IsaacEmpty";
import EditablePropertiesPanel from "../panelEditableProperties/EditablePropertiesPanel";
import MastProfileAndFoundationPanel from "../panelMastProfileAndFoundation/MastProfileAndFoundationPanel";
import DeselectedElementsPanel from "../panelDeselectedElementsPanel/DeselectedElementsPanel";

const ConstructionCalculationsBody: React.FunctionComponent<
  ConstructionCalculationsBodyProps
> = ({
  index,
  construction,
  constructionDetails,
  profileCalculationResults,
  selectedMastNo,
  calculations,
}) => {
  const mastResults = profileCalculationResults?.mastResults?.find(
    (mr: any) => mr.mastNumber === selectedMastNo,
  );

  return (
    <div className={styles.resultsAndPanels}>
      {mastResults !== undefined ? (
        <div className={`${styles.pageBrakeAvoid}`}>
          <Stack className={styles.resultTabs}>
            <h3 className={`${bodyStyles.resultsHeader} ${bodyStyles.noPrint}`}>
              Results
            </h3>
            <h3
              className={`${bodyStyles.resultsHeader} ${bodyStyles.print} ${styles.pageBrakeAvoid}`}>
              {index !== undefined ? `1.${index + 1}. Results` : ""}
            </h3>
            <div className={styles.resultNavigation}>
              {construction && (
                // Note: constructionDetails should be part of profileCalculationResults. Also as a concept profile calculation should be removed.
                <CalculationMastTabs
                  construction={construction}
                  profileCalculationResults={profileCalculationResults}
                  calculations={calculations}
                  selectedMastNo={selectedMastNo}
                />
              )}
            </div>
          </Stack>
          <Stack
            className={`${styles.resultContainer} ${styles.pageBrakeAvoid}`}
            horizontal
            wrap>
            <CalculationMastResults mastResults={mastResults} />
            <Stack grow={3} className={styles.panelsContainer}>
              <MastProfileAndFoundationPanel
                constructionDetails={constructionDetails}
                selectedMastNo={selectedMastNo}
                profileCalculationResults={profileCalculationResults}
              />
              <EditablePropertiesPanel
                construction={construction}
                profileCalculationResults={profileCalculationResults}
              />
              <DeselectedElementsPanel
                construction={construction}
                profileCalculationResults={profileCalculationResults}
              />
            </Stack>
          </Stack>
        </div>
      ) : (
        <IsaacEmpty message="No data to show for the current calculation." />
      )}
    </div>
  );
};

export default ConstructionCalculationsBody;
