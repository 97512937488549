import useConstructionHook from "../../../hooks/useConstructionHook";
import useIssacToastHook from "../../../hooks/useIssacToastHook";
import type {ModifiedConstructionElements} from "../../../types/IModifiedConstructionElements";
import IsaacToastWrapperType from "../../../types/IsaacToastWrapperType";

const useSaveModifiedConstructionHook = (): ((
  constructionId: string | undefined,
  calculationId: string,
  modifiedConstructionElements: ModifiedConstructionElements,
) => Promise<void>) => {
  const {saveModified} = useConstructionHook();
  const {withToast} = useIssacToastHook();

  const save = async (
    id: string | undefined,
    calculationId: string,
    modifiedConstructionElements: ModifiedConstructionElements,
  ): Promise<void> => {
    if (id === undefined) {
      throw new Error("Missing id");
    }

    // Note: with toast should be moved to top level
    await withToast(
      async () =>
        await saveModified(id, calculationId, modifiedConstructionElements),
      IsaacToastWrapperType.Full,
      undefined,
      `Triggering construction update...`,
      `Update construction triggered. You will receive a notification on completion.`,
      `Failed to trigger construction update!`,
    );
  };

  return save;
};

export default useSaveModifiedConstructionHook;
