import React from "react";
import styles from "../Constructions.module.scss";
import {Icon, type IColumn} from "@fluentui/react";
import ConstructionStatus from "../../../types/ConstructionStatus";

const useConstructionTableColumns = (): IColumn[] => {
  return [
    {
      key: "name",
      name: "Name",
      fieldName: "name",
      minWidth: 180,
      maxWidth: 400,
      flexGrow: 1,
      isResizable: true,
      isSorted: false,
      ariaLabel: "Name",
    },
    {
      key: "phase",
      name: "Phase",
      fieldName: "phase",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      isSorted: false,
      ariaLabel: "Phase",
    },
    {
      key: "locationCode",
      name: "Location code",
      fieldName: "locationCode",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      isSorted: false,
      ariaLabel: "Location code",
    },
    {
      key: "locationType",
      name: "Location type",
      fieldName: "locationType",
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      isSorted: false,
      ariaLabel: "Location type",
    },
    {
      key: "status",
      name: "State",
      fieldName: "status",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isSorted: false,
      ariaLabel: "Accepted",
      onRender: (item: any) => {
        if (item.status === ConstructionStatus.Accepted) {
          return (
            <Icon
              iconName="CheckboxCompositeReversed"
              className={styles.constructionIsAccepted}
              title="Construction is accepted. No changes can be performed."
            />
          );
        } else {
          return (
            <Icon
              iconName="ConstructionConeSolid"
              className={styles.constructionIsActive}
              title="Construction is active"
            />
          );
        }
      },
    },
    {
      key: "isGeometricallyValid",
      name: "Valid Geometrically",
      fieldName: "isGeometricallyValid",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isSorted: false,
      ariaLabel: "Valid Geometrically",
      onRender: (item: any) => {
        if (item.isGeometricallyValid === null) {
          return (
            <Icon
              iconName="Error"
              className={styles.validationIconNotValidated}
              title="Validation is not performed"
            />
          );
        } else if (item.isGeometricallyValid === true) {
          return (
            <Icon
              iconName="Completed"
              className={styles.validationIconSuccessful}
              title="Validation is successful"
            />
          );
        } else {
          let errors = "";
          if (
            item.geometricalValidationErrorMessages !== null &&
            item.geometricalValidationErrorMessages !== undefined
          ) {
            errors = item.geometricalValidationErrorMessages.join("\n");
            return (
              <Icon
                iconName="ErrorBadge"
                className={styles.validationIconFailedWithMessage}
                title={errors}
              />
            );
          }
          return (
            <Icon
              iconName="ErrorBadge"
              className={styles.validationIconFailed}
              title="No detailed message is provided"
            />
          );
        }
      },
    },
    {
      key: "sortCreatedOn",
      name: "Created",
      fieldName: "createdOn",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isSorted: true,
      isSortedDescending: true,
      ariaLabel: "Created",
    },
    {
      key: "createdBy",
      name: "Created by",
      fieldName: "createdBy",
      minWidth: 120,
      maxWidth: 140,
      isResizable: true,
      ariaLabel: "Created By",
    },
    {
      key: "tag",
      name: "Tags",
      fieldName: "tags",
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      ariaLabel: "Tags",
      onRender: (item: any) => {
        return (
          <div className={styles.tagsContainer}>
            {item.tags?.map((tag: any, i: any) => {
              return (
                <div className={styles.tag} key={i}>
                  {tag}
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];
};

export default useConstructionTableColumns;
