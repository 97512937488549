import React, {useEffect, useState} from "react";
import {Stack} from "@fluentui/react";

import styles from "./ConstructionCalculationsNote.module.scss";
import IsaacLoading from "../../components/isaacLoading/IsaacLoading";
import useConstructionCalculationsPageHook from "../constructionCalculations/hooks/useConstructionCalculationsPageHook";
import ConstructionCalculationsBody from "../constructionCalculations/components/body/ConstructionCalculationsBody";
import ConstructionBody from "../construction/components/body/ConstructionBody";
import IsaacPills from "../../components/isaacPills/IsaacPills";
import IsaacPdfViewer from "../../components/isaacPdfViewer/IsaacPdfViewer";
import useLoadPdfFromBlob from "./useLoadPdfFromBlob";

const ConstructionCalculationsNote: React.FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const {result: url} = useLoadPdfFromBlob(
    "Calculation_Notes_Static_Data_v1.2.pdf",
  );

  const {
    loadPageData: loadingCalculationsData,
    construction,
    calculations,
    constructionDetails,
    selectedCalculationResults,
  } = useConstructionCalculationsPageHook();

  useEffect(() => {
    const isEdge = navigator.userAgent.includes("Edg");

    if (!isEdge) {
      document.documentElement.classList.add("not-edge");
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      await loadingCalculationsData();
      setLoading(false);
    })();
  }, []);

  return (
    <div className={styles.noteResultsContainer}>
      <IsaacLoading loading={loading} message="Loading calculations note..." />
      {!loading && (
        <main className={styles.noteBody}>
          {construction != null && (
            <Stack.Item>
              <IsaacPills
                pills={[
                  {name: "Name", value: construction.name},
                  {name: "Location type", value: construction.locationType},
                  {name: "Location code", value: construction.locationCode},
                ]}
              />
            </Stack.Item>
          )}
          {constructionDetails?.masts.map((mast, index) => (
            <ConstructionCalculationsBody
              index={index}
              key={mast.number}
              calculations={calculations}
              construction={construction}
              constructionDetails={constructionDetails}
              selectedMastNo={mast.number}
              profileCalculationResults={selectedCalculationResults}
            />
          ))}

          <Stack.Item className={styles.noteConstructionData}>
            <h2
              className={`${styles.noteConstructionDataTitle} ${styles.noPrint}`}>
              Construction data
            </h2>
            <h2
              className={`${styles.noteConstructionDataTitle} ${styles.print}`}>
              1.2. Construction data
            </h2>
            <ConstructionBody construction={construction} />
          </Stack.Item>

          {url && construction && (
            <IsaacPdfViewer constructionNo={construction.number} url={url} />
          )}
        </main>
      )}
    </div>
  );
};

export default ConstructionCalculationsNote;
