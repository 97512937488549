import React from "react";

import styles from "./IsaacHeader.module.scss";
import PathConstants from "../../routes/pathConstants";

const IsaacHeader: React.FunctionComponent = () => {
  return (
    <section className={`${styles.headerSection} ${styles.noPrint}`}>
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.headerTop}>
            <a className={styles.headerLeft} href={PathConstants.Home}>
              <img src={`${process.env.PUBLIC_URL}/images/logo.png`} />
            </a>
          </div>
          <div className={styles.headerTitle}>
            <h1>{`Isaac - ${process.env.REACT_APP_ENV}`}</h1>
          </div>
        </div>
      </header>
    </section>
  );
};

export default IsaacHeader;
