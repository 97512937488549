import React from "react";
import {createBrowserRouter} from "react-router-dom";

import PathConstants from "./pathConstants";
import Login from "../pages/login/Login";
import AppLayout from "../pages/layout/AppLayout";
import Constructions from "../pages/constructions/Constructions";
import Construction from "../pages/construction/Construction";
import ConstructionCalculations from "../pages/constructionCalculations/ConstructionCalculations";
import ConstructionCalculationsNote from "../pages/constructionCalculationsNote/ConstructionCalculationsNote";
import PageNotFound from "../pages/notFound/PageNotFound";

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        index: true,
        path: PathConstants.Home,
        element: <Constructions />,
      },
      {
        index: true,
        path: `${PathConstants.Constructions}/:id`,
        element: <Construction />,
      },
      {
        index: true,
        path: `${PathConstants.Constructions}/:id/${PathConstants.Calculations}`,
        element: <ConstructionCalculations />,
      },
      {
        index: true,
        path: `${PathConstants.Constructions}/:id/${PathConstants.CalculationsNote}`,
        element: <ConstructionCalculationsNote />,
      },
    ],
  },
  {path: PathConstants.Login, element: <Login />},
  {path: "*", element: <PageNotFound />},
]);

export default router;
