import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {type ICommandBarItemProps, PrimaryButton} from "@fluentui/react";

import type IConstruction from "../../construction/types/IConstruction";
import IsaacToastWrapperType from "../../../types/IsaacToastWrapperType";
import ConstructionStatus from "../../../types/ConstructionStatus";
import useConstructionHook from "../../../hooks/useConstructionHook";
import useIssacToastHook from "../../../hooks/useIssacToastHook";

const useConstructionCalculationAcceptAndSaveHook = (
  construction: IConstruction | undefined,
  selectedCalculationResults: any | undefined,
  commandBarItems: ICommandBarItemProps[],
  isAllowedToTakeAction: boolean,
): {
  handleAccept: (() => Promise<void>) | undefined;
  handleOpen: () => void;
  open: boolean;
} => {
  const {t} = useTranslation(["constructionCalculations"]);
  const [open, setOpen] = useState<boolean>(false);
  const {accept} = useConstructionHook();
  const {withToast} = useIssacToastHook();
  const handleOpen = (): void => setOpen(prevState => !prevState);
  const handleAccept = async (): Promise<void> => {
    if (construction === undefined) return;

    const {
      editableConstructionProperties,
      deselectedConstructionElements,
      calculationId,
    } = selectedCalculationResults;

    await withToast(
      async () =>
        await accept(construction.id, calculationId as string, {
          deselectedElements: deselectedConstructionElements,
          editableProperties: editableConstructionProperties,
        }),
      IsaacToastWrapperType.Full,
      undefined,
      t(`acceptToastTriggerMessage`),
      t(`acceptToastTriggeredMessage`),
      t(`acceptToastSuccessErrorMessage`),
    );
  };
  const canBeAccepted =
    isAllowedToTakeAction &&
    construction?.status === ConstructionStatus.Registered;

  if (canBeAccepted) {
    commandBarItems.push({
      key: "acceptAndModify",
      onRender: () => (
        <PrimaryButton
          aria-label="Accept and save construction"
          text={t(`btnAcceptAndSaveConstruction`)}
          iconProps={{iconName: "Accept"}}
          onClick={handleOpen}
        />
      ),
    });
  }

  return {
    open,
    handleOpen,
    handleAccept: canBeAccepted ? handleAccept : undefined,
  };
};

export default useConstructionCalculationAcceptAndSaveHook;
