const enum PathConstants {
  Home = "/",
  Login = "login",
  NotFound = "/notfound",
  Constructions = "/constructions",
  Calculations = "calculations",
  CalculationsNote = "calculationsNote",
}

export default PathConstants;
