import React from "react";

import styles from "./IsaacPdfHeader.module.scss";

const IsaacPdfHeader: React.FunctionComponent<{headerNo: string}> = ({
  headerNo,
}) => {
  return (
    <header className={`${styles.headerContainer} ${styles.onlyPrint}`}>
      <img
        width={100}
        src={`${process.env.PUBLIC_URL}/images/TUCRAIL_Logo_Positif_RVB.jpg`}
      />
      <p className={styles.headerNote}>{`Construction note '${headerNo}'`}</p>
    </header>
  );
};

export default IsaacPdfHeader;
