import React from "react";
import {useTranslation} from "react-i18next";
import {DefaultButton, Icon, PrimaryButton, Stack, Text} from "@fluentui/react";

import styles from "./ConfirmAcceptAndSaveConstructionModal.module.scss";
import type ConfirmAcceptAndSaveConstructionModalProps from "./ConfirmAcceptAndSaveConstructionModalProps";
import IsaacModal from "../../../../components/isaacModal/IsaacModal";

const ConfirmAcceptAndSaveConstructionModal: React.FunctionComponent<
  ConfirmAcceptAndSaveConstructionModalProps
> = ({handleDismiss, handleConfirm, open}) => {
  const {t} = useTranslation(["constructionCalculations"]);

  const handleConfirmAction = (): void => {
    if (handleConfirm) {
      handleDismiss();
      void handleConfirm();
    }
  };

  return (
    <>
      {handleConfirm && (
        <IsaacModal
          isOpen={open}
          handleDismiss={handleDismiss}
          containerClassName={styles.container}
          overlay={{className: styles.overlay}}>
          <Stack>
            <Stack verticalAlign="center" horizontalAlign="center">
              <Icon iconName="Warning" className={styles.icon} />
              <Text variant="xLargePlus">{t(`acceptModalQuestion`)}</Text>
            </Stack>
            <Stack
              verticalAlign="center"
              horizontalAlign="center"
              className={styles.body}>
              <Text variant="large">{t(`acceptModalExplanation`)}</Text>

              <Text variant="large" className={styles.warningText}>
                {t(`acceptModalWarning`)}
              </Text>
            </Stack>
            <Stack horizontal className={styles.footer}>
              <Stack.Item className={styles.button}>
                <PrimaryButton onClick={handleConfirmAction} text="Confirm" />
              </Stack.Item>
              <Stack.Item className={styles.button}>
                <DefaultButton onClick={handleDismiss} text="Cancel" />
              </Stack.Item>
            </Stack>
          </Stack>
        </IsaacModal>
      )}
    </>
  );
};

export default ConfirmAcceptAndSaveConstructionModal;
