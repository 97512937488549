import useApi from "../services/api/hooks/useApi";
import ApiHttpVerbs from "../services/api/types/ApiHttpVerbs";
import Api from "../services/auth/types/IsaacApi";
import {getICCConstructions} from "../pages/constructions/legacy/LegacyCode";
import type ApiResponse from "../services/api/types/ApiResponse";
import type IConstructionHook from "../types/IConstructionHook";
import type IConstruction from "../pages/construction/types/IConstruction";
import type {IDefaultConstructionCalculationParameters} from "../types/IDefaultConstructionCalculationParameters";
import type {ModifiedConstructionElements} from "../types/IModifiedConstructionElements";

/** Hook that defines all BE calls for the construction entity. */
export const useConstructionHook = (): IConstructionHook => {
  const {fetchData} = useApi();

  const getAllConstructions = async (): Promise<ApiResponse<any[]>> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions`;
    const response = await fetchData<any[]>(url, Api.ICC);

    return {
      ...response,
      data: response?.data != null ? getICCConstructions(response.data) : null,
    };
  };

  const getConstructionById = async (
    id: string,
  ): Promise<ApiResponse<IConstruction>> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions/${id}/gui`;
    const response = await fetchData<IConstruction>(url, Api.ICC);

    return response;
  };

  const deleteConstructionById = async (
    constructionId: string,
    correlationGuid: string,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions/${constructionId}`;
    const response = await fetchData<any>(
      url,
      Api.ICC,
      false,
      ApiHttpVerbs.DELETE,
      {
        "X-Correlation-ID": correlationGuid,
      },
    );
    return response;
  };

  const validateEditConstructionGeometrically = async (
    constructionId: string,
    collection: ModifiedConstructionElements,
  ): Promise<ApiResponse<ModifiedConstructionElements>> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions/${constructionId}/editConstructionValidateGeometrically`;
    const response = await fetchData<ModifiedConstructionElements>(
      url,
      Api.ICC,
      false,
      ApiHttpVerbs.POST,
      {},
      JSON.stringify(collection),
    );
    return response;
  };

  const getDefaultConstructionCalculationParameters = async (): Promise<
    ApiResponse<IDefaultConstructionCalculationParameters>
  > => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions/defaultCalculationParameters`;
    const response = await fetchData<IDefaultConstructionCalculationParameters>(
      url,
      Api.ICC,
      false,
    );

    return response;
  };

  const saveModified = async (
    constructionId: string,
    calculationId: string,
    modifiedConstructionElements: ModifiedConstructionElements,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/constructions/${constructionId}/saveModified`;
    const response = await fetchData<any>(
      url,
      Api.ISAAC,
      false,
      ApiHttpVerbs.POST,
      {},
      JSON.stringify({modifiedConstructionElements, calculationId}),
    );
    return response;
  };

  const accept = async (
    constructionId: string,
    calculationId: string,
    modifiedConstructionElements: ModifiedConstructionElements,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/constructions/${constructionId}/accept`;
    const response = await fetchData<any>(
      url,
      Api.ISAAC,
      false,
      ApiHttpVerbs.POST,
      {},
      JSON.stringify({modifiedConstructionElements, calculationId}),
    );

    return response;
  };

  return {
    getAllConstructions,
    getConstructionById,
    deleteConstructionById,
    validateEditConstructionGeometrically,
    getDefaultConstructionCalculationParameters,
    saveModified,
    accept,
  };
};

export default useConstructionHook;
