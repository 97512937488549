import React, {useEffect} from "react";
import {Document, Page, pdfjs} from "react-pdf";

import styles from "./IsaacPdfViewer.module.scss";
import IsaacPdfHeader from "../isaacPdfHeader/IsaacPdfHeader";

const IsaacPdfViewer: React.FunctionComponent<{
  url: string;
  constructionNo: string;
}> = ({url, constructionNo}) => {
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs",
      import.meta.url,
    ).toString();

    return () => {
      pdfjs.GlobalWorkerOptions.workerSrc = "";
    };
  }, []);

  return (
    <Document renderMode="canvas" file={url}>
      {Array.from(Array(3).keys()).map((i, index) => (
        <div key={index} className={styles.pageContainer}>
          <IsaacPdfHeader headerNo={constructionNo} />
          <Page
            scale={1}
            key={index}
            pageNumber={i + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            height={1800}
          />
        </div>
      ))}
    </Document>
  );
};

export default IsaacPdfViewer;
