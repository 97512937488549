import React, {useEffect, useState} from "react";
import {Icon, IconButton, PrimaryButton, Stack, Text} from "@fluentui/react";
import {useDispatch} from "react-redux";

import styles from "./IsaacErrorModal.module.scss";
import type IsaacErrorModalProps from "../isaacModalError/IsaacErrorModalProps";
import IsaacModal from "../isaacModal/IsaacModal";
import {flush} from "../../pages/layout/errorsSlice";

const IsaacErrorModal: React.FunctionComponent<IsaacErrorModalProps> = ({
  errors,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (errors) {
      setIsOpen(true);
    }
  }, [errors]);

  return (
    <IsaacModal
      isOpen={isOpen}
      containerClassName={styles.modalContainer}
      overlay={{className: styles.overlay}}
      handleDismiss={() => {
        setIsOpen(false);
      }}>
      <Stack
        verticalAlign="center"
        horizontalAlign="center"
        verticalFill
        className={styles.container}>
        <div className={styles.closeBtnContainer}>
          <IconButton
            iconProps={{
              iconName: "ChromeClose",
            }}
            className={styles.closeBtn}
            onClick={() => setIsOpen(false)}
          />
        </div>

        <Icon iconName="ErrorBadge" className={styles.icon} />
        <Text variant="xxLarge">Oops! Something went wrong.</Text>

        {errors === undefined && (
          <Text variant="large">API communication error occurred.</Text>
        )}
        {errors?.map((e, i) => (
          <Text key={i} variant="large" className={styles.errorsContainer}>
            {e.message ?? e.exception}
          </Text>
        ))}
        <PrimaryButton
          className={styles.retryBtn}
          text="Retry"
          onClick={() => {
            dispatch(flush());
            window.location.reload();
          }}
        />
      </Stack>
    </IsaacModal>
  );
};

export default IsaacErrorModal;
